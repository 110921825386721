import { Stack, Column } from "../Components/layout.jsx"
import PropTypes from "prop-types"
import  { useState } from "react";
import { useEffect } from "react";
import ReactPlayer from "react-player";
import { Carousel } from 'react-bootstrap'
import { useMediaQuery } from "../Context/mediaQuery.js";
import { getSetting, getVideosData } from "../Database/dbmanager.js";

const VideographyPage = (props) => {
    const [videos, updateVideos] = useState({});
    const {device,breakpoints} = useMediaQuery();
    const [playing, isPlayingList] = useState([]);
    const getVideos = async() => {
        const settings = await getSetting();
        let featuredVideoId = settings?.featuredVideoId;
        let videos = await getVideosData();
        let tempVM = {featured:videos.filter(v=>v.id===featuredVideoId)[0],videos:videos};
        let tempVideostatus = [];
      
       
        let featuredVideo = tempVM.featured;
        tempVM.videos = [featuredVideo,...videos.filter(v=>v.id!==featuredVideo.id)];
        tempVM.videos.forEach(v => {
            tempVideostatus.push(false);
        });

        isPlayingList(tempVideostatus);

        updateVideos(tempVM);
    }
    useEffect(() => {
        getVideos();
    }, []);

   

    const buildPlayer = () => {
        return <Column style={{width: "100%", height:device==="xs"?"100%": "100%", padding:device==="xs"?"":"20px", color: "white" }}>
            {/* <Row><LineIcon name="video" style={{ fontSize: 40 }} /> <h1 style={{ paddingLeft: 10 }}> Video Catalogue </h1>  </Row> */}
           
            <div style={{ width: "100%", height:device==="xs"?"100%": 500, borderRadius: 20, background: "black" }}>

                {/* <ReactPlayer autoplay width={"100%"} controls style = {{borderRadius: 20}}  url={videos.featured?.url??""}/> */}

                <Carousel onSlide={()=>{
                    playing.fill(false,0,playing.length);
                    isPlayingList(playing);
                }} interval={null} style={{height:device==="xs"?"100%":"",width:device==="xs"?"100%":""}}>
                    {((videos?.videos ?? [])).map((v, i) => {

                        return <Carousel.Item key={"ss_" + i} style={{ height: device==="xs"?"100%":"500px", width: "100%" }}>
                            <div style={{ height: device==="xs"?"100%":"500px", width: "100%" }}><ReactPlayer width={"100%"} height="100%" playing={playing[i]} onPause={
                                ()=>{
                                    playing.fill(false,0,playing.length);
                                    isPlayingList(playing);
                                }
                            } onPlay={
                                ()=>{
                                   
                                    playing.fill(false,0,playing.length);
                                    playing[i] = true;
                                    isPlayingList(playing);
                                }
                            } controls style={{ borderRadius: 20 }} url={v?.url ?? ""} />
                            </div>
                        </Carousel.Item>

                    })}
                </Carousel>
            </div>


        </Column>
    }
    return <div {...props} >
        <Stack style={{height:"100vh"}}>
            <Column style={{ height: "100%", width: "100%" }}>

                <div style={{ height: '100%', background: "black" }}></div>

            </Column>
            <div style={{ height: "100%", width: "100%", background: "black" }} />
            <Column alignment="space-between" style={{ height: "100%", padding: "0px 0px", borderTopRightRadius: 20 }}>

                <Column style={{ height: "100%", width: "100%", position: "relative", }}>
                    <div style={{ width: "100%", height:device==="xs"?"100%": "",  margin: "0 auto", maxWidth: breakpoints.lg,}}>
                        {buildPlayer()}
                    </div>

                </Column>
            </Column>
        </Stack>
    </div>
}

export default VideographyPage;

VideographyPage.propTypes = {
    featured: PropTypes.object,

}