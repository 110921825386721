
import './App.css';
import './index.css'
import 'semantic-ui-css/semantic.min.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Home from './pages/home'
import { SnackbarProvider } from 'notistack';
import MediaQueryProvider from './Context/mediaQuery';
import DiscographyPage from './pages/discography';
import { Controller, Scene } from 'react-scrollmagic';

import styled from 'styled-components';
import VideographyPage from './pages/videography';
import 'bootstrap/dist/css/bootstrap.min.css';
import GalleryPage from './pages/gallery';
import NavBar from './Components/nav_bar';
import ContactPage from './pages/contact';
import PlayerProvider from './Context/videoPlayercontroller';
import HeadlineAd from './pages/headline_ad';
import MediaPlayerProvider from './Context/mediaController';

import SiteMarquee from './Components/_site_marquee';
import { useEffect } from 'react';
import SettingsProvider from './Context/settings';
const SectionWipesStyled = styled.div`
  overflow: hidden;
  .panel {
    height: 100vh;
    width: 100vw;
    background:black;

  }
  
  .panel span {
    position: relative;
    display: block;
    overflow: visible;
    font-size: 80px;
  }
  
  .panel.blue {
    background-color: #F9F9F9;
  }
  
  .panel.turqoise {
    background-color: #38ced7;
  }
  
  .panel.green {
    background-color: #22d659;
    margin-bottom: 800px;
  }
  
  .panel.bordeaux {
    background-color: #953543;
  }
`;

function App() {
 
  const pageData = {
    new: {
    icon: "new",
    page: <Home />
  },
    home: {
      icon: "home",
      page: <HeadlineAd />
    },
    discography: {
      icon: "headphone",
      page: <DiscographyPage />
    },
    videography: {
      icon: "video",
      page: <VideographyPage />
    },
    gallery: {
      icon: "gallery",
      page: <GalleryPage />
    },
    contact: {
      icon: "info",
      page: <ContactPage />
    }
  }
  useEffect(()=>{
    const paths = window.location.pathname.split("/");
    const path = paths[paths.length-1];
    switch(path){
      case "discography":
        document.getElementById("discography").scrollIntoView();
        break;
      case "videography":
        document.getElementById("videography").scrollIntoView();
        break;
      case "gallery":
        document.getElementById("gallery").scrollIntoView();
        break;
      case "contact":
        document.getElementById("contact").scrollIntoView();
        break;
      default:
        document.getElementById("new").scrollIntoView();
        break;
    }
  },[])
  return (
    <PlayerProvider>
    <SnackbarProvider>
      <MediaQueryProvider>
        <SettingsProvider>
        <MediaPlayerProvider>
          <SiteMarquee/>
            <SectionWipesStyled>
            <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
              <Scene pin>
                <div className="panel blue" id={"new"} ><Home /></div>
              </Scene>
                {
                Object.keys(pageData).slice(1).map((data,index) => {
                  return <Scene key={"sc_"+index}>
                    <div className="panel" id={data} >
                      {pageData[data].page} 
                    </div>
                  </Scene>
                })
              }
            </Controller>
          </SectionWipesStyled>
            <NavBar pageData={pageData} /> 
        </MediaPlayerProvider>
        </SettingsProvider>
      </MediaQueryProvider>
    </SnackbarProvider>
    </PlayerProvider>

  );
}

export default App;
