import { Stack, Column, Row } from "../Components/layout.jsx"
import { FloatingLabel, Form as BForm, Modal } from "react-bootstrap";
import PropTypes from "prop-types"
import React, { useState,useRef } from "react";
import { useEffect } from "react";
import 'swiper/components/navigation/navigation.min.css'
import { Button, Dimmer, Divider, Form, Grid, Header, Loader, Message, Segment } from 'semantic-ui-react'
// Import Swiper styles
import "swiper/swiper.min.css";
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'
import { useMediaQuery } from "../Context/mediaQuery.js";
import { sendEmailWithEmailJS } from "../Context/emailer.js";
import axios from "axios";
const ContactPage = (props) => {
     const {  device } = useMediaQuery();
    const [nl_email,setEmail] = useState("");
    const [open, setOpen] = React.useState(false);
    const [openEmail, setEmailOpen] = React.useState(false);
    const [emailMessage,updateEmailMessage] = useState({email:"",subject:"",body:""})
    const [sending,setSending] = useState(false)
    const emailRef = useRef(null);
    const messageRef = useRef(null);
    const subjectRef = useRef(null);
    useEffect(() => {
    }, []);

      const subscribeToNewsLetter = async(email)=>{
        const headers = {
            'Content-Type': 'application/json'
        };
        try{
            //https://salamay-server.herokuapp.com
        const response = await axios.post("https://salamay-server.herokuapp.com/api/salamay/subscribe", JSON.stringify({email:email}),{headers});
        if(response.status < 200 || response.status > 300){
               
            }else{
               
                setOpen(true)
            }
        
    } catch (err) {
    
        console.error(`mailchimp: An error occurred:`, err);
        throw err;
    }

        // const option =new Request({
        //     url:'https://us14.api.mailchimp.com/3.0/lists/a0e6c07930',
        //     method:"POST",
        //     body:JSON.stringify({
        //         members:[
        //             {
        //                 email_address: email,
        //                 status:"subscribed",
        //             }
        //         ]
        //     }),
        //     headers:{
        //         Authorization: "auth b8e7a1cc48c0ddc76fcaef0929b876a7-us14"
        //     }
        // });
        // let res;
        // try{
        //     res = await fetch(option);
        //    
        //     if(res.status < 200 || res.status > 300){
        //        
        //     }else{
        //        
        //         setOpen(true)
        //     }
        // }catch(e){

        //    
        // }
    }

    const sendEmail = async()=>{
        if(emailRef.current.value!=="" &&  messageRef.current.value !== ""){
        setSending(true);
        if(emailMessage.email!==""){
             sendEmailWithEmailJS(
            {
              to: `info@salamaymusic.com`,
              from: emailMessage.email??emailRef.current.value,
              subject: emailMessage.subject??subjectRef.current.value,
              body: emailMessage.body??messageRef.current.value,
          },
          ()=>{
            setSending(false);
              setEmailOpen(true)
             emailRef.current.value="";
             messageRef.current.value="";
             subjectRef.current.value="";
             updateEmailMessage({email:"",subject:"",body:""});
          },
           (err)=>{
                 
              }
          );
        }else{
            alert("email is empty")
        }
    }
    }
    const socials = {
        instagram: "https://www.instagram.com/salamay_sound",
        twitter: "https://twitter.com/salamay_music",
        youtube: "https://www.youtube.com/channel/UCcOku5Y8JBPMe3LRHcHTDCw",
    }
    const buildPlayer = () => {
        return <Grid textAlign='center' columns={device === "xs" ? 1 : 3} style={{ height: '100vh', color: "white", padding: "0 20px",   overflowY:"auto",
        overflowX:"hidden" }} verticalAlign='middle'>
            <Grid.Row>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <div style={{ height: device === "xs" ? 20 : 0 }} />
                    <Header as='h2' style={{ color: "white" }} textAlign='center'>
                        SUBSCRIBE
                    </Header>
                    <label style={{textTransform:"uppercase"}}>Enter email for updates </label>
                    <div style={{ height: device === "xs" ? 20 : 20 }} />
                    <Form size='large'>
                        <Segment stacked>
                            <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail address' onKeyUp={(e)=>{
                               setEmail(e.target.value);
                            }}/>
                            <Button secondary fluid size='large' onClick={()=>{subscribeToNewsLetter(nl_email)}}>
                                Sign up
                            </Button>
                        </Segment>
                    </Form>
                    <Message>
                        <Row alignment="centered">{
                            Object.keys(socials).map((s,i) => {
                                return <Button key={"soc"+i} circular color={s} icon={s} onClick={() => { window.open(socials[s], "_blank").focus() }} />
                            })
                        }</Row>
                    </Message>
                    <div style={{ height: device === "xs" ? 20 : 0 }} />
                </Grid.Column>
                <Grid.Column width={device === "xs" ? 2 : 2}>
                    <Divider inverted horizontal>Or</Divider>
                </Grid.Column>

                <Grid.Column style={{ maxWidth: 450 }}>
                    <div style={{ height: device === "xs" ? 20 : 0 }} />
                    <Header as='h2' style={{ color: "white" }} textAlign='center'>
                        Contact Me
                    </Header>
                    <Form size='large'>
                        <Segment style={{ backgroundColor: "#282424", color: "white" }} stacked>
                            {/* <Row alignment={"space-between"}>  <FloatingLabel
                                controlId="floatingInput"
                                label="First name"
                                className="mb-3"
                            >
                                <BForm.Control style={{ background: "#342e2e", borderColor: "#4a4444", color: "white" }} type="name" placeholder="" />
                            </FloatingLabel> <FloatingLabel
                                controlId="floatingInput1"
                                label="Last name"
                                className="mb-3"
                            >
                                    <BForm.Control style={{ background: "#342e2e", borderColor: "#4a4444", color: "white" }} type="name" placeholder="" />
                                </FloatingLabel></Row> */}
                            <FloatingLabel
                                controlId="floatingInput2"
                                label={emailMessage.email === "" ? "Email Address":""}
                                className="mb-3"
                            >
                                <BForm.Control ref={emailRef} style={{ background: "#342e2e", borderColor: "#4a4444", color: "white" }} type="email" onKeyUp={(e)=>{
                               emailMessage.email = e.target.value;
                               updateEmailMessage({...emailMessage})
                            }} placeholder="" />
                            </FloatingLabel>

                            <FloatingLabel
                                controlId="floatingInput1"
                                label= {  emailMessage.subject === "" ? "Subject":""}
                                className="mb-3"
                            >
                                <BForm.Control  ref={subjectRef} style={{ background: "#342e2e", borderColor: "#4a4444", color: "white" }} type="text" onKeyUp={(e)=>{
                               emailMessage.subject = e.target.value;
                               updateEmailMessage({...emailMessage})
                            }} placeholder="" />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput3"
                                label="Enter you message"
                                className="mb-3"

                            >
                                <BForm.Control ref={messageRef} as="textarea" onKeyUp={(e)=>{
                               emailMessage.body = e.target.value;
                               updateEmailMessage({...emailMessage})
                            }} style={{ background: "#342e2e", borderColor: "#4a4444", color: "white" }} type="email" placeholder="" />
                            </FloatingLabel>
                            {sending?    <Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>: <Button onClick={sendEmail} style={{ backgroundColor: "#4a3830", color: "white" }} fluid size='large'>
                                Send
                            </Button>}
                        </Segment>
                    </Form>
                </Grid.Column>

            </Grid.Row>

        </Grid>
    }
    return <><Column style={{ height: "100%", width: "100%", position: "relative", }}>
        <Stack>
            <div style={{ height: "100%", width: "100%", background: "black" }} />
            {buildPlayer()}
        </Stack>

    </Column>
    <Modal
    size="xs"
    style={{backdropFilter:"blur(10px)",WebkitBackdropFilter:"blur(10px"}}
    show={open}
    onBackdropClick={()=>{setOpen(false)}}
    onHide={()=>{setOpen(false)}}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    
    <Modal.Body>
    <h4 style={{fontWeight:"bold",textAlign:"center"}}>THANK YOU</h4>
    {/* <p style={{textAlign:"center"}}>Thanks for Subscribing to our news-letter.<br/>I Can't wait to share the journey with you!</p> */}
    </Modal.Body>
  </Modal>

  <Modal
    size="xs"
    style={{backdropFilter:"blur(10px)"}}
    show={openEmail}
    onBackdropClick={()=>{setEmailOpen(false)}}
    onHide={()=>{setEmailOpen(false)}}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    
    <Modal.Body>
    <h4 style={{fontWeight:"bold",textAlign:"center"}}>Email sent!</h4>
    <p style={{textAlign:"center"}}>Thanks, we'll get back to you as soon as we can.</p>
    </Modal.Body>
  </Modal>
    </>

}

export default ContactPage;

ContactPage.propTypes = {
    featured: PropTypes.object,

}