
import {child, ref, getDatabase, get} from 'firebase/database';
import {collection, setDoc,doc, onSnapshot, getFirestore} from 'firebase/firestore';
import firebaseApp from "../firebase_app";
const db = getDatabase(firebaseApp);
const firestore = getFirestore(firebaseApp);

export const getMarquee= ()=>{
    return new Promise((resolve, reject) => {
        (get(child(ref(db), 'marquee'))).then((snapshot) => {
            if (snapshot.exists()) {
                let data = snapshot.val();
                resolve(data);
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export const getSetting= ()=>{
    return new Promise((resolve, reject) => {
        (get(child(ref(db), 'settings'))).then((snapshot) => {
            if (snapshot.exists()) {
                let data = snapshot.val();
                resolve(data);
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export const getAnnouncementAlert = ()=>{
    return new Promise((resolve, reject) => {
        (get(child(ref(db), 'announcements/justIn'))).then((snapshot) => {
            if (snapshot.exists()) {
                let data = snapshot.val();
                resolve(data);
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export const getHomeType = ()=>{
    return new Promise((resolve, reject) => {
        (get(child(ref(db), 'featured-announcement'))).then((snapshot) => {
            if (snapshot.exists()) {
                let data = snapshot.val();
                resolve(data);
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export const getAnnouncement = ()=>{
   
    
    return new Promise((resolve, reject) => {
        (get(child(ref(db), 'featured-announcement'))).then((snapshot) => {
            const id = snapshot.val();
            get(child(ref(db), `announcements/${id}`)).then((snapshot) => {
                if (snapshot.exists()) {
               
                    let data = snapshot.val();
                   
                    resolve(data);
                } else {
                    reject("No data available");
                }
            }).catch((error) => {
                reject(error);
            });
        });
    });
}
export const getFeaturedBio = ()=>{
   
    return new Promise((resolve, reject)=>{
        get(child(ref(db), 'FeaturedBio')).then((snapshot) => {
            if (snapshot.exists()) {
               
                let data = snapshot.val();
               
                resolve(data);
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export const getFeaturedData = ()=>{
   
    return new Promise((resolve, reject)=>{
        get(child(ref(db), 'Featured')).then((snapshot) => {
            if (snapshot.exists()) {
               
                let data = snapshot.val();
               
                resolve(Object.values(data));
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export const getSong=({songId})=>{
   
    return new Promise((resolve, reject)=>{
        get(child(ref(db), 'songs/'+songId,)).then((snapshot) => {
            if (snapshot.exists()) {
               
                resolve(snapshot.val());
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export const getSongs=()=>{
    return new Promise((resolve, reject)=>{
        get(child(ref(db), 'songs',)).then((snapshot) => {
            if (snapshot.exists()) {
                resolve(Object.values(snapshot.val()));
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export const getVideosData=()=>{
    return new Promise((resolve, reject)=>{
        get(child(ref(db), 'Videos',)).then((snapshot) => {
            if (snapshot.exists()) {
                resolve(Object.values(snapshot.val()));
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export const getGalleryData=()=>{
    return new Promise((resolve, reject)=>{
        get(child(ref(db), 'Gallery',)).then((snapshot) => {
            if (snapshot.exists()) {
                resolve(Object.values(snapshot.val()));
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}
export const retrievedDrawnLines = (callback)=>{
    return new Promise((resolve, reject)=>{
        const today = new Date();
        const col = collection(firestore, 'DrawnLines');
        const docID = `${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`;
        onSnapshot(doc(col, docID), (snapshot)=>{
            //console.log(snapshot.exists(), snapshot.data());
            if(snapshot.exists()){
                callback(snapshot.data().lines);
            }else{
                resolve([]);
            }
        }, (error)=>{
            reject(error);
        });
    });
}
export const saveDrawnLinesToDB = (lines)=>{
   
    return new Promise((resolve, reject)=>{
        const today = new Date();
        const col =  'DrawnLines';
        const docId = `${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`;
      
        setDoc(doc(firestore, col,docId), {
            lines: lines,
            date: today
        }).then(()=>{
            
            resolve();
        }).catch((error)=>{
            reject(error);
        });
    });
}
export const eraseAllDrawLines = ()=>{
    return new Promise((resolve, reject)=>{
        const today = new Date();
        const col =  'DrawnLines';
        const docId = `${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`;
        setDoc(doc(firestore, col,docId), {
            lines: [],
            date: today
        }).then(()=>{
            
            resolve();
        }).catch((error)=>{
            reject(error);
        });
    });
}
export const getAlbums = ()=>{
    return new Promise((resolve, reject)=>{
        get(child(ref(db), 'albums')).then(async(snapshot) => {
            if (snapshot.exists()) {
                let albums = snapshot.val()??{};
               
                resolve(Object.values(albums).sort((a,b)=>b.releaseDate-a.releaseDate));
            } else {
                reject("No data available");
            }
        }).catch((error) => {
            reject(error);
        });
    });
}