import React from 'react';
import '../Utils/utils.css'
import PropTypes from 'prop-types';

const Column = (props)=>{
    return <div id={props.id}  className={(props.alignment??"centered")+"-col "+props.className} style={{...props.style, alignContent: props.crossAlignment}}>
    {props.children}
</div>
}
Column.propTypes={
    alignment: PropTypes.oneOf(['start','end','centered','space-between']),
    crossAlignment: PropTypes.oneOf(["start","end","center"]),
    className: PropTypes.string,
    style: PropTypes.object
}
export default Column;