import { Stack, Column, } from "../Components/layout.jsx"
import PropTypes from "prop-types"
import  { useState ,useEffect} from "react";
import 'swiper/components/navigation/navigation.min.css'
import Slider from 'react-touch-drag-slider'


import LineIcon from "react-lineicons";
// Import Swiper styles
import "swiper/swiper.min.css";
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'
import { motion } from "framer-motion";
import { getGalleryData } from "../Database/dbmanager.js";
import { useAsync } from "react-async";
const GalleryPage = (props) => {
    const [current, setcurrent] = useState(0);
    const {data} = useAsync({promiseFn: getGalleryData});

    useEffect(() => {
        setcurrent(0)
    }, []);

    const buildPlayer = () => {
        return <Stack style={{ padding: "20px", height: "100%", width: "100%", color: "white" }}>

          

         <Slider
                onSlideComplete={setcurrent}
                onSlideStart={(i) => {
                   
                }}
                activeIndex={current}
                threshHold={100}
                transition={0.5}
                scaleOnDrag={true}
            > 
                {
                    data.map((p, i) => {
                        return <img key={"gal_"+i} alt={p.img} src={p.img}/>
                    })
                }
             </Slider> 
                <Column alignment={"centered"} style={{ height: "100%",sWidth: "20px", }} zStyle={{left:20}}>
                    {current > 0 ? <LineIcon name="chevron-left-circle" style={{ color: "white", fontSize:30 }}  onClick={()=>{
                        setcurrent(Math.max(0,current-1));
                    }}/> : <></>}
                </Column>
                <Column alignment={"centered"} style={{ height: "100%",sWidth: "20px",  }} zStyle={{right:20}}>
                    {current < data.length ?<LineIcon name="chevron-right-circle" style={{ color: "white", fontSize:30 }} onClick={()=>{
                        setcurrent(Math.min(data.length-1,current+1))
                       
                    }}/>:<></>}
                </Column>
            {/* <h1 style={{ paddingLeft: 10, sheight: 20 }}> Salamay. </h1> */}

        </Stack>
    }
    return <Column style={{ height: "100%", width: "100%", position: "relative",overflow:"hidden" }}>
        {data ? <Stack>
        <div style={{ height: "100%", width: "100%", background: "black" }} />
            <motion.img alt={data[current].img} initial={{ opacity: 0.4 }} animate={{ opacity: 1 }} transition={{ type: "tween" }} src={data[current].img} style={{ width: "100%", height: "100%", objectFit: "cover", filter: 'blur(20px)' }} blurRadius={5} />
            <div style={{ height: "100%", width: "100%", background: "radial-gradient(rgb(28 28 28 / 72%),black)" }} />
            {buildPlayer()}
        </Stack>:<></>}

    </Column>
}

export default GalleryPage;

GalleryPage.propTypes = {
    featured: PropTypes.object,

}