import axios from "axios";
import { init } from "emailjs-com"


const config = {
    apiKey: "821f2a5de2866204a6b733f1373a492d",
    userId: "user_uzQFG8YSYQwlvsLzIsanh",
    templateId: "template_tzlj1hb"
}
export const configEmailJs = () => {
    init(config.userId);
}
export const sendEmailWithEmailJS = (payload, success_callback, error_callback) => {
    configEmailJs();
   
    // send(
    //     'service_ocr4tex',config.templateId,
    //     {message: payload.body, subject:payload.subject, to_name:payload.to,from_name: payload.from, reply_to: payload.to}
    // ).then(res=>success_callback(res)).catch(res=>{
    //    //
    //     error_callback(res);})

    sendWithNodeMailer(payload).then(res => success_callback(res)).catch(res => {
        //    //
        error_callback(res);
    })

}



export const sendWithNodeMailer = async (payload) => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };

       
        const response = await axios.post("https://salamay-server.herokuapp.com/api/salamay/sendEmail", JSON.stringify(payload),{headers});
        if (response.data.success) 
        return true;
    } catch (err) {
    
        console.error(`send365Email: An error occurred:`, err);
        throw err;
    }

}
//     let transporter = nodemailer.createTransport({
//     service: 'gmail',
//     auth: {
//         user: 'olamidepeters@gmail.com',
//     }
// });
//     let mailOptions = {
//         from: payload.from, // Something like: Jane Doe <janedoe@gmail.com>
//         to: payload.to,
//         subject: payload.subject, // email subject
//         html:payload.html // email content in HTML
//     };
//     //console.log(mailOptions);
//     let request=null;

//     try{
//  request = await transporter.sendMail(mailOptions);
//  //console.log("frond");
//     }catch(e){
//        
//     }
//   return request;

