import Column from "../Components/column";
import { motion } from 'framer-motion';

import { useMediaQuery } from '../Context/mediaQuery';
import {  Button, Message, MessageHeader } from 'semantic-ui-react';

import {useAsync} from "react-async";
import { getAnnouncementAlert } from '../Database/dbmanager';

const AnnounceMessage = ({onClick = ()=>{}})=>{
    const {data} = useAsync({promiseFn: getAnnouncementAlert,});
    const { device } = useMediaQuery();
    const justIn = data;
    return justIn ?  (justIn?.showAlert && justIn?.expiryDate > Date.now()) ? <motion.div initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }} style={{
        bottom:0,left:0,height:device === "xs" || device === "md" ?"16vh":"14vh"
    }}transition={{ delay: 0, type:"tween",ease:"easeIn"}}>
        <Message color={justIn?.backgroundColor??'green'} floating style={{
backgroundRepeat: 'no-repeat',
backgroundSize: 'cover',
color:justIn?.color??'white',
height:"100%",
backgroundPosition: 'left',
backgroundImage: `url("${justIn?.backgroundImage}")`,
    zIndex:100,width: device === "xs" || device === "md" ?"":'100vw', textAlign:"center"}}
    >
        <MessageHeader style={{marginBottom:"10px"}}>
            {justIn?.title}
        </MessageHeader>
        <Column>
        <p>{justIn?.message}</p>
        {<Button color={justIn?.backgroundColor??'green'} compact onClick={onClick}>Learn more</Button>}
        </Column>
  
   </Message>
        </motion.div>:<></>:<></>
}

export default AnnounceMessage;