
import PropTypes from 'prop-types';
const AlbumModule = (props) => {


    return <div style={{
        boxShadow: "0px 0px 25px 5px rgba(0, 0, 0, 0.25)",
        borderRadius: props.borderRadius ?? "10px",
        position: "relative",
        width: props.width ?? 250,
        height: props.height ?? 250,
    }}>
        <div className="ui instant move reveal" >
            <div className="visible content">
                <img alt="" src={props.cover} style={{
                    width:"100%",
                    height: "100%",
                    borderRadius: props.borderRadius ?? "10px",
                }} className="ui small image" />
            </div>
            <div className="hidden content">
                <img alt="" src={props.backcover} style={{
                   width:"100%",
                   height: "100%",
                    borderRadius: props.borderRadius ?? "10px",
                    background:"black"
                }} className="ui small image" />
            </div>
        </div>

    </div>
}
AlbumModule.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    borderRadius: PropTypes.number.isRequired,
    cover: PropTypes.string.isRequired,
    style: PropTypes.object

}
export default AlbumModule