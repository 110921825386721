import { createContext,useContext, useEffect,useState } from "react";
export const useMediaQuery = ()=>{
    return useContext(MediaQueryContext)
}
export const MediaQueryContext = createContext();

export default function MediaQueryProvider(props) {

    const [device,setDevice] = useState("pc");
    const [breakpoints,setBreakpoints] = useState({
        "xs":500,
        "md":1200,
        "pc":1440,
        "lg":1620,
        "xl":2080
    });
    useEffect(()=>{  
        updateDevice();
        window.addEventListener("resize",updateDevice);
    })
    const isLargeDevice = ()=>{
        return device === "pc" || device==="lg"||device==="xl";
    }
    const updateDevice = ()=>{
        if(window.innerWidth<=breakpoints.xs)
        {
            setDevice('xs');
        }else if(window.innerWidth<=breakpoints.md&&window.innerWidth>breakpoints.xs){
            setDevice('md');
        }
        else if(window.innerWidth<=breakpoints.pc&&window.innerWidth>breakpoints.md){
            setDevice('pc');
        }
        else if(window.innerWidth<=breakpoints.lg&&window.innerWidth>breakpoints.pc){
            setDevice('lg');
        }
        else {
            setDevice('xl')
          }
       //  
    }
    return <MediaQueryContext.Provider value={{
        device,
        breakpoints,
        setBreakpoints,
        updateDevice,
        isLargeDevice
    }}>
        {props.children}
    </MediaQueryContext.Provider>
}