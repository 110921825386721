import  Lottie from 'lottie-react';
import { List, Image } from 'semantic-ui-react';
import { useMediaPlayer } from '../Context/mediaController.js';
import { motion } from 'framer-motion';
import { ConvertSecondsToMinutes } from "../Logic/time.js";
import Column from './column.jsx';
import Row from './row.jsx';
import playingAnimIcon from '../assets/lottie/playing.json';
import { useMediaQuery } from '../Context/mediaQuery.js';
const SongModule = ({song ,openMusicModal}) => {
    const { playing,loading, play,pause, currentSong, setCurrentSong, currentTheme } = useMediaPlayer();
    const {device} = useMediaQuery();
    const playSong = (song) => {
        if(currentSong.id === song.id && playing) return pause();
        setCurrentSong(song);
        play(song);
       
    }
    const characterAnimation = {
        initial: {
          opacity: 0,
          x: -10,
        },
        animate: {
          opacity: 1,
          x: 0,
        },
        whileHover:{
            scale:1.15,
            color:'#fff'
        }
      };

    return !song? <></> : 
    
          <List.Item key={"disc" + song.id} style={{ color: currentSong.id === song.id ? (currentTheme?.accent ?? "green") : "white" }}>
    <List.Content  floated='left'   onClick={() => { playSong(song); }}>
        {( currentSong.id === song.id && playing) ? <List.Icon  name='pause' /> : <List.Icon name='play' />}
        <Image avatar src={song?.cover ?? ""} />
    </List.Content>
    <List.Content   onClick={() => { playSong(song); }}>
        {/* +(Songs[id].feature.length>0 ?'(feat.  '+Songs[id].feature.join(", ")+')':"" */}
        <List.Header style={{ color: currentSong.id === song.id ? (currentTheme?.accent ?? "green") : "white" }} >{(song?.title ?? "")}</List.Header>
        <List.Description>{(song?.artist ?? "") + (song?.feature?.length > 0 ? ', ' +song.feature.join(", ") : "")}</List.Description>
    </List.Content>

    <List.Content floated='right'>
      <Row crossAlignment={'center'}>
      <Row alignment={"centered"} style={{margin: "0 10px"}} >
      { device === "xs" ?  <Column>
            <List.Icon style = {{color: "#878787"}} name='bars' onClick={()=>openMusicModal(song)}/>
        </Column>
       :<>
        <motion.div {...characterAnimation}>
        <Column>
            <List.Icon style = {{color: "#878787"}} name='spotify' onClick={()=>{ window.open(song?.src['spotify'],"_blank") }} />
        </Column>
        </motion.div>
        <motion.div {...characterAnimation}>
        <Column>
            <List.Icon style = {{color: "#878787"}} name='amazon' onClick={()=>{ window.open(song?.src['amazon'],"_blank") }} />
        </Column>
        </motion.div>
        <motion.div {...characterAnimation}>
        <Column>
            <List.Icon style = {{color: "#878787"}} name='apple' onClick={()=>{ window.open(song?.src['apple'],"_blank") }} />
        </Column>
        </motion.div></>
        }
      </Row>
        {!(currentSong.id === song.id &&loading) ? (!( currentSong.id === song.id && playing)  ? <></> : <div style={{height: 20, width: 20,margin:'0 8px'}}><Lottie animationData={playingAnimIcon} /></div>) : <div class="ui active inline loader small" style = {{margin: "0 8px"}}></div>}
        
        <Column ><List.Description>
            {ConvertSecondsToMinutes(song?.duration ?? 0)}
        </List.Description></Column>
      </Row>
    </List.Content>

</List.Item>
  
}
export default SongModule;