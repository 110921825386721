export const Intro = {
    title: "Intro",
    desc: "",
    release: 1636722005000,
    id: "i1636722005000",
    artist: "Salamay",
    feature: [],
    duration: 42,
    cover: "https://firebasestorage.googleapis.com/v0/b/salamaysite.appspot.com/o/IMG-1717.PNG?alt=media&token=fabd7eab-8884-48c3-a484-6b187be6dc92",
    producedBy: [],
    writtenBy: [],
    lyrics: [],
    src: {spotify:"https://open.spotify.com/track/7jE2ClklBoRSM1RqVXh4Ax?si=f5b035164f0a4305",
    apple:"https://music.apple.com/ie/album/intro/1591355293?i=1591355294",amazon:"https://music.amazon.com/albums/B09JYFS7ZD?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_ojczRYMMkwPFM9D19O8kP7oFf&trackAsin=B09JYGB7T7"}

}
export const StressOut = {
    title: "Stress Out My Mind",
    desc: "",
    release: 1666911600000,
    id: "so1666911600000",
    artist: "Salamay",
    feature: [],
    duration: 196,
    cover: "https://i.ibb.co/qRmcV12/STRESS.png",
    producedBy: [],
    writtenBy: [],
    lyrics: [],
    src: {spotify:"https://open.spotify.com/track/0xRD0NEChOaToDYzvWs3hT?si=f9eea871009243eb",
    apple:"https://music.apple.com/us/album/stress-out-my-mind-single/1647917999",amazon:"https://music.amazon.com/albums/B0BH186DXD?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_IrxQwwTDUdxWukJU7KR7qxXCU&trackAsin=B0BH19WH2M"}

}
export const TakeItEasy = {
    title: "Take It Easy",
    desc: "",
    release: 1636722005000,
    id: "tie1636722005000",
    artist: "Salamay",
    feature: [],
    duration: 283,
    cover: "https://firebasestorage.googleapis.com/v0/b/salamaysite.appspot.com/o/IMG-1717.PNG?alt=media&token=fabd7eab-8884-48c3-a484-6b187be6dc92",
    src: {spotify:"https://open.spotify.com/track/30EPPyRldC8PjBBCtcPE7d?si=4d664109c1e94678",
    apple:"https://music.apple.com/ie/album/take-it-easy/1591355293?i=1591355295",amazon:"https://music.amazon.com/albums/B09JYFS7ZD?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_jKvmllHo58QQ1cuweifNjoesG&trackAsin=B09JYPQ38W"

}

}
export const Thoughts = {
    title: "Th(oug)hts",
    desc: "",
    release: 1636722005000,
    id: "ts1636722005000",
    artist: "Salamay",
    feature: ["Dami Mila"],
    duration: 230,
    cover: "https://firebasestorage.googleapis.com/v0/b/salamaysite.appspot.com/o/IMG-1717.PNG?alt=media&token=fabd7eab-8884-48c3-a484-6b187be6dc92",
    src: {spotify:"https://open.spotify.com/track/2QafyctnXWOihQpcJglVk3?si=c319e57d856a4b56",
    apple:"https://music.apple.com/ie/album/tho-ugh-ts-feat-dami-mila/1591355293?i=1591355296",amazon:"https://music.amazon.com/albums/B09JYFS7ZD?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_iFHRWRVD4d4twFSBoLxj5DAT7&trackAsin=B09JYT6TZZ"
}

}
export const RochelleSkit = {
    title: "Rochelle(Skit)",
    desc: "",
    release: 1636722005000,
    id: "rchs1636722005000",
    artist: "Salamay",
    feature: [],
    duration: 45,
    cover: "https://firebasestorage.googleapis.com/v0/b/salamaysite.appspot.com/o/IMG-1717.PNG?alt=media&token=fabd7eab-8884-48c3-a484-6b187be6dc92",
    src: {
        spotify:"https://open.spotify.com/track/478MsyUfBucUjm8AOD3II6?si=ade509f2116a4466",
        apple:"https://music.apple.com/ie/album/rochelle-skit/1591355293?i=1591355298",
        amazon:"https://music.amazon.com/albums/B09JYFS7ZD?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_77ITEOGe7lnhwLujIImLum1OH&trackAsin=B09JYFL9R5"
    },

}
export const Rochelle = {
    title: "Rochelle / Trust Issues",
    desc: "",
    release: 1636722005000,
    id: "rch1636722005000",
    artist: "Salamay",
    feature: [],
    duration: 248,
    cover: "https://firebasestorage.googleapis.com/v0/b/salamaysite.appspot.com/o/IMG-1717.PNG?alt=media&token=fabd7eab-8884-48c3-a484-6b187be6dc92",
    src: {
        spotify:"https://open.spotify.com/track/71sQXvYNO0IZyuXoKaQbTz?si=aa32a88e6c5d4af5",
        apple:"https://music.apple.com/ie/album/rochelle-trust-issues/1591355293?i=1591355299",
        amazon:"https://music.amazon.com/albums/B09JYFS7ZD?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_CvDvD6X9DbalKQIWvAL2dt4nv&trackAsin=B09JYTN66J"
    }

}
export const Numb = {
    title: "Numb",
    desc: "",
    release: 1636722005000,
    id: "nb1636722005000",
    artist: "Salamay",
    feature: ["Dami Mila"],
    duration: 365,
    cover: "https://firebasestorage.googleapis.com/v0/b/salamaysite.appspot.com/o/IMG-1717.PNG?alt=media&token=fabd7eab-8884-48c3-a484-6b187be6dc92",
    src: {
        spotify:"https://open.spotify.com/track/2FV4mEzzogjxHC2UxQqcXC?si=ca3df38c3cdf4263",
        apple:"https://music.apple.com/ie/album/numb-feat-dami-mila/1591355293?i=1591355300",
        amazon:"https://music.amazon.com/albums/B09JYFS7ZD?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_0Fy4NCebhF8wWczYNVM7H9bCn&trackAsin=B09JZ3GGSL"
    }

}
export const LetmeInInterlude = {
    title: "Let Me In (Interlude)",
    desc: "",
    release: 1636722005000,
    id: "lmii1636722005000",
    artist: "Salamay",
    feature: ["Brightraay"],
    duration: 118,
    cover: "https://firebasestorage.googleapis.com/v0/b/salamaysite.appspot.com/o/IMG-1717.PNG?alt=media&token=fabd7eab-8884-48c3-a484-6b187be6dc92",
    src: {spotify:"https://open.spotify.com/track/26Ai5nfEYA1cNnrEI4V21T?si=3887698dc25c41d5",apple:"https://music.apple.com/ie/album/let-me-in-interlude-feat-brightraay/1591355293?i=1591355301",amazon:"https://music.amazon.com/albums/B09JYFS7ZD?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_Fy1iygbFi4ADaeIHhdM3iyzIm&trackAsin=B09JYVM19M"}

}
export const LetmeIn = {
    title: "Let Me In",
    desc: "",
    release: 1636722005000,
    id: "lmi1636722005000",
    artist: "Salamay",
    feature: [],
    duration: 315,
    cover: "https://firebasestorage.googleapis.com/v0/b/salamaysite.appspot.com/o/IMG-1717.PNG?alt=media&token=fabd7eab-8884-48c3-a484-6b187be6dc92",
    src: {spotify:"https://open.spotify.com/track/6S1PGxRnOUWjJFzudUzhBv?si=31a7bcfa81f4402b",apple:"https://music.apple.com/ie/album/let-me-in/1591355293?i=1591355302",amazon:"https://music.amazon.com/albums/B09JYFS7ZD?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_YDmXTTnvzkg25ENbM8NapeEas&trackAsin=B09JYNSLTT"}

}

export const PSM = {
    title: "P.S.M",
    desc: "",
    release: 1636722005000,
    id: "psm1636722005000",
    artist: "Salamay",
    feature: [],
    duration: 513,
    cover: "https://firebasestorage.googleapis.com/v0/b/salamaysite.appspot.com/o/IMG-1717.PNG?alt=media&token=fabd7eab-8884-48c3-a484-6b187be6dc92",
    src: {spotify:"https://open.spotify.com/track/1uE5oKjlbLOCuHOiea1Npa?si=635043c205094384",apple:"https://music.apple.com/ie/album/p-s-m/1591355293?i=1591355303",amazon:"https://music.amazon.com/albums/B09JYFS7ZD?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_EvpPNgPu8auW0CUwGyyJE7MpP&trackAsin=B09JYW2CGW"}

}
export const SalamaysInterlude = {
    title: "Salamay's Interlude",
    desc: "",
    release: 1636722005000,
    id: "si1636722005000",
    artist: "Salamay",
    feature: ["LVNUE"],
    duration: 159,
    cover: "https://i.ibb.co/vkcM9SQ/Salamay-feat-L-Avenue-21.png",
    src: {spotify:"https://open.spotify.com/track/4FLh40VPC0nEyXORkDkQpv?si=9af99814c33f4290",apple:"https://music.apple.com/ie/album/salamays-interlude-feat-lvnue/1504488157?i=1504488162",amazon:"https://music.amazon.com/albums/B086CD4QW8?marketplaceId=A3K6Y4MI8GDYMT&musicTerritory=IE&ref=dm_sh_6Ecbsa4LI1OQz7b7iGHeUUCoZ&trackAsin=B086CC245D"}

}
export const Songs = {
    "so1666911600000": StressOut,
    "i1636722005000": Intro,
    "tie1636722005000": TakeItEasy,
    "ts1636722005000": Thoughts,
    "rchs1636722005000": RochelleSkit,
    "rch1636722005000": Rochelle,
    "nb1636722005000": Numb,
    "lmii1636722005000": LetmeInInterlude,
    "lmi1636722005000": LetmeIn,
    "psm1636722005000": PSM,
    "si1636722005000": SalamaysInterlude
}