import { useAsync } from "react-async";
import { getMarquee } from "../Database/dbmanager";
import Marquee from 'react-fast-marquee';
import Column from "./column";
const SiteMarquee = () => {
    const {data} = useAsync({promiseFn: getMarquee,});
    return  !(data?.showMarquee) ? <></> : <Marquee style={{position: 'fixed', zIndex: 1000, top: 0, backgroundColor:'#2e512e', padding: 3}} gradient gradientColor={[150,168,150]}>
        {(data?.messages??[]).map((message, i)=><Column> <p key={"mmq"+i} style={{color:"white", marginRight: 5,wordSpacing:2}}>{message}</p></Column>)}
  </Marquee>
}
export default SiteMarquee;