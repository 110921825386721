import { useAsync } from "react-async";
import Stack from "../Components/stack";
import { getAnnouncement } from "../Database/dbmanager";
import { parseLink } from "../Context/mediaController";
import Column from "../Components/column";
import { motion } from 'framer-motion';
import AnnounceMessage from "../Components/_announcementMessgae";
import Announcer from "../Components/announcement";
import { Button } from "semantic-ui-react";
import { useState } from "react";
import { useEffect } from "react";

const HomeVideoView = () => {
    const {data} = useAsync({promiseFn: getAnnouncement,});
    const [openModal2,setOpenModal2] = useState(true);
    useEffect(()=>{
        if(localStorage.getItem('announcement') === "true"){
            
            setOpenModal2(false);
        }else{
           
            setOpenModal2(true);
           
           
        }
    },[])
    const _buildPage = ()=>{
        const styles={
            font: {
                color: "white",
                fontFamily:"Lusitana",
                
            }
        }
        
        return <Stack>
        <div style={{objectFit:'cover',backgroundColor:"black", width:"100%",height:"100%"}}/>
        <video playsInline autoPlay loop muted src={parseLink(data?.src??"")} style={{objectFit:'cover',width:"100%",height:"100%", pointerEvents: 'none'}}/>
        <img  alt="" src="https://img.freepik.com/free-vector/seamless-pattern-made-dots_1053-342.jpg" style={{objectFit: "cover", width:"100%", height:"100%", opacity: 0.5, filter: 'brightness(0.1)'}}/>
        <div style={{objectFit:'cover',backgroundColor:"black", width:"100%",height:"100%",opacity: 0.4}}/>
        <Column alignment={"centered"} crossAlignment={"center"} style={{height:"100%", width:"100%", flexWrap: "wrap"}}>
            <motion.h1 style={{...styles.font,color:"white",fontSize:"4vh",letterSpacing: 15.2,fontWeight:"bold",textAlign:"center"}}>{data?.title}</motion.h1>
            <motion.p style={{...styles.font,color:"white",fontSize:"1.8vh",padding: 10 ,maxWidth:550,textAlign:"center"}}>{data?.bio}</motion.p>
        </Column>
        {
         <div style={{position: 'relative', height: "100%", width: "100", pointerEvents: 'none'}} zStyle = {{pointerEvent: 'none'}}>
                  <div style = {{right: 20, bottom: 0, position: 'absolute'}}>
                    <AnnounceMessage />
                    </div></div>}
        </Stack>
    }
    return <>
     {data ? _buildPage() : <Stack>
        <div style={{objectFit:'cover',backgroundColor:"black", width:"100%",height:"100%"}}/>
        <img alt="" src="https://www.ranchiwomenscollege.org/wpimages/wp08feb7ab_05_06.jpg" style={{objectFit: "cover", width:"100%", height:"100%", opacity: 0.4, filter: 'brightness(0.35)'}}/>
     
        </Stack>}
        {<Announcer onHide={() => { setOpenModal2(false); localStorage.setItem('announcement',"true"); }} openModal={openModal2} 
        footer= {<Button  onClick={()=>{
            document.getElementById("contact").scrollIntoView();
            setTimeout(()=>{
                setOpenModal2(false)
            }, 1000 );
        }} style={{ backgroundColor: "#4a3830", color: "white" }} fluid size='large'>
        Subscribe now!
    </Button>}   />}
    </> 
}
export default HomeVideoView;