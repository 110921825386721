import React from 'react';
import { getSetting } from '../Database/dbmanager';
export const SettingsContext = React.createContext();

export const useSettings = () => React.useContext(SettingsContext);
const SettingsProvider = (props) => {
    const [settings, setSettings] = React.useState({});
    React.useEffect(() => {
        const fetchSettings = async () => {
            const settings = await getSetting();
            setSettings(settings);
        };
        fetchSettings();
    }, []);
    return (
        <SettingsContext.Provider
            value={{
                settings,
            }}
        >
            {props.children}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;