import Stack from "../Components/stack";
import Logo from "../assets/salamayWhite.png";
import Blackboard from '../assets/blackboard.svg';
import SCenter from '../assets/S1.png';
import SRight from '../assets/S2.png';
import SLeft from '../assets/S3.png';
import { useMediaQuery } from "../Context/mediaQuery";
import Row from "../Components/row";
import Column from "../Components/column";
import RollCall from "../assets/roll_call.svg";
import { motion, useAnimation } from 'framer-motion';
import { useInView } from "react-intersection-observer";
import React,{ useEffect, useState } from "react";
import {TbArrowBack, TbEraser, TbPencil} from 'react-icons/tb';
import { MouseDraw } from "../Components/_mouse_draw";
import RippleAnimation from '../assets/lottie/ripple.json';
import  Lottie  from "lottie-react";
const HeadlineAd = () => {
    const { breakpoints, device, isLargeDevice } = useMediaQuery();
    const drawRef = React.useRef(null);
    const ctrls = useAnimation();
    const [penMode, setPenMode] = useState(false);
  
    const [color, setColor] = useState("#898989");
    const [thickness, setThickness] = useState(3);

    const { ref, inView } = useInView({
      threshold: 0.5,
      triggerOnce: true,
    });
    
    useEffect(() => {
      if (inView) {
        ctrls.start("visible");
      }
      if (!inView) {
        ctrls.start("hidden");
      }
    }, [ctrls, inView]);
    
    const characterAnimation = {
      initial: {
        opacity: 0,
        y: -10,
      },
      animate: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 1,
          ease: [0.2, 0.65, 0.3, 0.9],
        },
      },
    };
    const _buildHeadline = () => {
        return <motion.img {...characterAnimation} transition = { {delay: 60 } } src={RollCall} style={{height: device === 'xs' ? 70 :  90,}}/>
    }
    const _buildCharacters = () => {
        const imageStyle = {
            objectFit: 'contain',
            pointerEvents: 'none'
        };
        return <Column style={{width: '100%', height: '100vh', position:'relative'}}>
            <Row style={{width: '100vw', position: 'absolute', bottom: '0', left: '0',}} alignment = 'centered'>
                <div style={{ width: device === 'xs' ? '100vw' : device === 'md' ? '90vw' : '50vw', height: device === 'xs' ? '42vh' : '45vh',overflow:'hidden', }}>
           <Row style={{height:"100%",width:"100%"}} crossAlignment='end'>
           <img alt="" zStyle={{left:0}} style={{...imageStyle,width:"33%",transform: 'translateY(14vh)'}} src = {SLeft}/>
            <img alt="" zStyle={{left:0,right:0, }} style={{...imageStyle,width:"33%",transform: 'translateY(13vh)'}} src = {SCenter}/>
            <img alt="" zStyle={{right:0,}} style={{...imageStyle,width:"33%",transform: 'translateY(15vh)'}} src = {SRight}/>
                       

           </Row>
           </div>
            </Row>
            <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: device === "xs" ? 200 : 100 }} transition={{ delay: 0.7, type: "tween" }} >
            <Row alignment={'centered'}>
              {_buildCrownLogo()}
            </Row>
</motion.div>
            </Column>
    }
    const _buildCrownLogo = () => {
      return <motion.img src={Logo} style={{ width: device === "xs" ? 120 : 150, height: device === "xs" ? 120 : 150 }} />
    }
    const _buildPen = () => {
      return <motion.div style={{height: 100, width: 100, pointerEvents: 'all'}} whileHover={{cursor: 'pointer'}} whileTap={{scale: 0.9}} onClick={()=>{
        setPenMode(true)
      }}>
        <Stack height = "100%">
          <Lottie animationData={RippleAnimation} />
          <Column alignment={'centered'} style={{flexWrap: 'wrap', height:"100%"}} crossAlignment={'center'}>
          <TbPencil size={20} color={'#fff'}/>
          </Column>
      </Stack>
        </motion.div>
    }
    const _buildEraser = () => {
      const styles = {
        font:{
            fontFamily: 'Berton',
            color: '#fff',
            textAlign: 'center',
            fontSize: device === 'xs' ? 14 :  16,
            textDecoration:'underline'
            
        }
    }
      return <Column alignment={'centered'} crossAlignment={'center'} style={{flexWrap: 'wrap', width: 150}}>
        
     
        <Row alignment={'centered'} crossAlignment={'center'} style={{flexWrap: 'wrap',
         pointerEvents: 'all',
          margin:"10px 0"}}>
        {[1, 2, 3, 4, 5].map((t, index)=>{
          return <Column alignment={"centered"}><motion.div key = {index} whileHover={{scale:1.1,  cursor: 'pointer'}} style={{
            padding: color === 
          t ? 4 : 1 ,
            pointerEvents: 'all',  borderRadius: "100%",
             border: `2px ${t === thickness ?  'white' : 'transparent'} solid`, 
            }} 
             onClick = {()=>{
            drawRef.current.changeThickness(t);
            setThickness(t);

          }} ><div style={{backgroundColor: color, height: (t * 10)*0.5, width:(t*10)*0.5, borderRadius: "100%",}}/></motion.div></Column>
        })}
      </Row>
     
        <Row alignment={'centered'} crossAlignment={'center'} style={{flexWrap: 'wrap',
         pointerEvents: 'all',
          margin:"10px 0"}}>
        {["#898989", "#7b9c7b", "#9c7b90","#7b7e9c"].map((c, index)=>{
          return <motion.div key = {index} whileHover={{scale:1.1,  cursor: 'pointer'}} style={{
            padding: color === 
          c ? 4 : 1 ,
            pointerEvents: 'all',  borderRadius: "100%",
             border: `2px ${c === color ?  'white' : 'transparent'} solid`, 
            }} 
             onClick = {()=>{
            drawRef.current.changeColor(c);
            setColor(c);

          }} ><div style={{backgroundColor: c, height: 20, width:20, borderRadius: "100%",}}/></motion.div>
        })}
      </Row>
      <motion.div whileHover={{scale:1.1,  cursor: 'pointer'}} style={{pointerEvents: 'all'}} onClick = {()=>{
        drawRef.current.clearDrawing();
      }} >
        <Row alignment={'centered'} crossAlignment={'center'} style={{flexWrap: 'wrap', pointerEvents: 'all', margin:"10px 0"}}>
        <TbEraser color="white" size={22} /> <p style = {styles.font}>
      Wipe Board
    </p>
      </Row>
        </motion.div> 
       { penMode ? <motion.div onClick = {()=>{
        setPenMode(false);
      }}  whileHover={{scale:1.1, cursor: 'pointer'}} style={{pointerEvents: 'all'}}> <Row  alignment={'centered'} crossAlignment={'center'} style={{flexWrap: 'wrap', pointerEvents: 'all'}}>
        <TbArrowBack color="white" size={22}/> <p style = {styles.font}>
      ROLL CALL
    </p>
      </Row></motion.div> : <></>}
      </Column>
    }
    const _buildPage = () => {
        const styles = {
            font:{
                fontFamily: 'Berton',
                color: '#fff',
                textAlign: 'center',
                fontSize: device === 'xs' ? 16 :  20,
                
            }
        }
        return <div ref={ref}  style = {{transform: !isLargeDevice() ? "scale(1.0)" : "scale(1.2)", pointerEvents: 'none'}}>
            <Column alignment={'centered'} style={{padding: 80, flexWrap: 'wrap'}} crossAlignment={'center'}>
              <div style={{maxWidth: '50vw',pointerEvents: 'all',}}>

             
            <motion.p {...characterAnimation} transition = { {delay: 0 } } style={styles.font}>{"Salamay Presents"}</motion.p>
            <motion.p {...characterAnimation} transition = { {delay: 20 } } style={{...styles.font, fontFamily: 'BertonV'}}>{"Debut Headline Show"}</motion.p>
            <Row alignment={'centered'}>
               {_buildHeadline()}
            </Row>
              
               <motion.p {...characterAnimation} transition = { {delay: 30 } } style={{...styles.font, fontFamily: 'FloatingTin'}}>{"22 April 2023"}</motion.p>
               <motion.p {...characterAnimation} transition = { {delay: 40 } } style={{...styles.font, fontFamily: 'FloatingTin'}}>{"The Sound House, Dublin | 7:15pm"}</motion.p>
               <motion.p {...characterAnimation} whileHover={{scale: 1.1, cursor: 'pointer'}} style={{...styles.font,  fontFamily: 'FloatingTin', textDecoration: 'underline',}} onClick={()=>window.open('https://thesoundhouse.ie/tc-events/salamay-presents-roll-call/','_blank')} >Book Tickets Here</motion.p>
            </div>
             </Column>
        </div>
    }
    return (
         <div style={{height: "100vh", width: '100vw', background:'#000',maxWidth: breakpoints.lg,overflow: 'hidden'}}>
            <Stack>
                <div style = {{height:"100%", width:"100%", background: "black"}}/>
                <img src = {Blackboard} alt="" zStyle={{overflow:'hidden'}} style={{height: "100vh",width:"100%", transform: 'scale(3)',objectFit:"cover"}}/>
                 { <Row style = {{height: "100%", width: "100%"}} alignment = "space-between">
                <svg width="100vw" height="100vh">
                <MouseDraw ref={drawRef} x={0} y={0} width={"100vw"} isMobile = {!isLargeDevice()} height={"100vh"} defThickness={3} enableDrawingMode = {penMode} />
               </svg>
                </Row>}
                <div style={{pointerEvents: 'none',width:'unset'}}>
                  {_buildCharacters()}
                </div>
                <Column style={{pointerEvents: 'none', height: device === 'xs' ? "100%" : "unset" }} alignment = "centered">
                 {penMode ? <></> : _buildPage()}
                </Column>
                { <div style={{position: 'relative', height: "100%", width: "100", pointerEvents: 'none'}} zStyle = {{pointerEvent: 'none'}}>
                  <div style = {{right: 20, bottom: isLargeDevice() ? 0 : 160, position: 'absolute'}}>
                  { penMode ? _buildEraser() : _buildPen()}
                  </div>
                </div>}
            </Stack>
            
        </div>
                
    );
    };

    export default HeadlineAd;