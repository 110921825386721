
export default function Utils() {

}

Utils.prototype = {
    constructor: Utils,
    isElementInView: function (el, id) {
        var top = el.offsetTop-20;
       // var left = el.offsetLeft;
        var height = el.offsetHeight;
        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
           // left += el.offsetLeft;
        }
        return (
           ( top+height) <= (window.pageYOffset + window.innerHeight)
            // left < (window.pageXOffset + window.innerWidth) &&
            // (top + height) > window.pageYOffset 
            // (left + width) > window.pageXOffset
        );

    }
};
