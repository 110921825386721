import React from 'react';
import '../Utils/utils.css'
import PropTypes from 'prop-types';

const Row = (props)=>{
    return <div id={props.id}  className={(props.alignment??"left")+"-row "+props.className} style={{...props.style, alignContent: props.crossAlignment}}>
    {props.children}
</div>
}

 Row.propTypes={
     alignment: PropTypes.oneOf(["left","right","centered",'space-between']),
     crossAlignment: PropTypes.oneOf(["start","end","centered"]),
     className: PropTypes.string,
     style: PropTypes.object
 }
export default Row;