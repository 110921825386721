// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDD4W-pNK-XCtOTSG8B9miFSjFApSckYGI",
  authDomain: "salamaymusic-d4205.firebaseapp.com",
  databaseURL: "https://salamaymusic-d4205-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "salamaymusic-d4205",
  storageBucket: "salamaymusic-d4205.appspot.com",
  messagingSenderId: "816127660220",
  appId: "1:816127660220:web:c50740f5e3665167d900b9",
  measurementId: "G-7LV4BFE1KM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;