import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Image, List } from "semantic-ui-react";
import { SocialIcon } from "react-social-icons";
import Row from "./row";
const ListenToMusic = ({currentSong = {}, onHide, openModal})=>{
    return   <Modal
    size="xs"
    style={{backdropFilter:"blur(10px)",WebkitBackdropFilter:"blur(10px"}}
    show={openModal}
    onBackdropClick={onHide}
    onHide={onHide}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
       
      <Modal.Title id="contained-modal-title-vcenter">
      <List verticalAlign='middle' style={{ cursor: "pointer", }} >
      <List.Item onClick={onHide}>
                          <Image avatar src={currentSong?.cover ?? ""} />
                          <List.Content>
                              {/* +(Songs[id].feature.length>0 ?'(feat.  '+Songs[id].feature.join(", ")+')':"" */}
                              <List.Header style={{fontSize:"16px",fontWeight:"bold"}} >{(currentSong?.title ?? "")}</List.Header>
                              <List.Description style={{fontSize:"12px"}}>{(currentSong?.artist ?? "") + (currentSong?.feature?.length > 0 ? ', ' + currentSong?.feature?.join(", ") : "")}</List.Description>
                          </List.Content>

                      </List.Item></List>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <h4 style={{fontWeight:"bold",textAlign:"center"}}>Listen Now</h4>
      <Row alignment={"centered"}>
          {
         Object.keys(currentSong?.src??{}).filter((song)=>currentSong?.src[song]!==(""|undefined)).map((song,index)=>{
             return song==="apple" ?  
             <img alt="amazon music" key={"listen_"+index} style={{borderRadius:"100em",objectFit:"contain",margin:"0 5px",height:35,width:35,cursor:"pointer"}} onClick={()=>{window.open(currentSong?.src[song],"_blank")}} avatar src={ "https://pbs.twimg.com/profile_images/1431129444362579971/jGrgSKDD_400x400.jpg"} /> :
              song==="amazon" ? 
               <img  style={{borderRadius:"100em",objectFit:"contain",margin:"0 5px",height:35,width:35,cursor:"pointer"}} alt="mini" key={"listen_"+index}  onClick={()=>{window.open(currentSong?.src[song],"_blank")}} src={ "http://assets.stickpng.com/thumbs/62b1e81756b6848f8bec9037.png"} /> : 
               <SocialIcon key={"listen_"+index} url={currentSong?.src[song]} style={{margin:"0 5px",height:35,width:35}}/>
         })
     }
      </Row>
     
    </Modal.Body>
  </Modal>
}
export default ListenToMusic;
ListenToMusic.propTypes={
    onHide: PropTypes.func,
    currentSong:PropTypes.object,
    openModal:PropTypes.bool

}
