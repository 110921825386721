/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";

export const useMediaPlayer =()=>{
  return useContext(MediaContext);
}
    //parse google drive link
export  const parseLink = (link) => {
      if (link) {
         
          let linkArray = link.split("/");
          let id = linkArray[linkArray.length - 2];
          let newLink = `https://drive.google.com/uc?export=download&id=${id}`;
         
          return newLink;
      }
  }
export const useMediaState = ()=>{

    const [playing,setPlaying] = useState(false);
    const [loading,setLoading] = useState(false);
    const [currentSong,setCurrentSong] = useState({});
    const [currentTheme,updateCurrentTheme] = useState();

    const [audio] = useState(new Audio());



  useEffect(()=>{
    audio.addEventListener('ended', function() {
      setPlaying(false);
      setLoading(false);
     
    });
  },[])
    const play = async(song)=>{
        setLoading(true);
       
        if(currentSong?.link?.src !== song?.link?.src){
          audio.src = parseLink(song?.link?.src);
          audio.onended = function() {
            setPlaying(false);
            setLoading(false);
           
          }
      }
        const audioPromise = audio.play()
        if (audioPromise !== undefined) {
          audioPromise
            .then(_ => {
              setPlaying(true);
              setLoading(false);
             
            })
            .catch(err => {
              // catch dom exception
              console.info(err)
            })
        }
        setCurrentSong(song);
    }
    const pause = async()=>{
        setPlaying(false);
        audio.pause()
        
       
          
    }
   
    return {
        playing,
        loading,
        currentSong,
        currentTheme,
        play,
        pause,
        setCurrentSong,
        updateCurrentTheme
    }
}
const MediaContext = React.createContext();

const MediaPlayerProvider = ({children})=>{
    const mediaState = useMediaState();
    return <MediaContext.Provider value={mediaState}>{children}</MediaContext.Provider>
}
export default MediaPlayerProvider;