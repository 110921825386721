
import { Intro, TakeItEasy, Thoughts , RochelleSkit, Rochelle, Numb, LetmeInInterlude, LetmeIn, PSM, SalamaysInterlude, StressOut} from "./mockSongs"
export const EP = {
    cover:"https://i.ibb.co/pvMZmM6/IMG-1717.png",
    backcover:"https://i.ibb.co/2tVnqG8/IMG-1718.png",
    desc:"Throughout this project, a connection is created with the artist as we explore a myriad of emotions through soft cadences, sweet melodies and effortless rhymes and lyricism. A raw display of storytelling, with a buzz of excitement and the edge of a new sound, Salamay delivers a debut project worth listening to and spending time digesting. Music is indeed food for the soul and this project feeds it.",
    releaseDate:1636722005000,
    title:"E.P.",
    type:"EP",
    subtitle:"Enemy of Progress",
    listen:{
        spotify:"https://open.spotify.com/album/1MysoRv0rJ1XL5XUNig6Ta",
        youtube:"",
        itunes:"",
        soundcloud:"",
        deezer:"",
        tidal:""
    },
    tracklist:[
        Intro.id,
        TakeItEasy.id,
        Thoughts.id,
        RochelleSkit.id,
        Rochelle.id,
        Numb.id,
        LetmeInInterlude.id,
        LetmeIn.id,
        PSM.id,
    ]
}


export const SalamayInterlude = {
    cover:"https://i.ibb.co/vkcM9SQ/Salamay-feat-L-Avenue-21.png",
    backcover:"https://i.ibb.co/vkcM9SQ/Salamay-feat-L-Avenue-21.png",
    desc:"",
    releaseDate:1587643200000,
    title:"Salamay's Interlude",
    type:"EP",
    subtitle:"",
    listen:{
        spotify:"",
        youtube:"",
        itunes:"",
        soundcloud:"",
        deezer:"",
        tidal:""
    },
    tracklist:[
        SalamaysInterlude.id,
    ]
}
export const Stress = {
    ...StressOut,
    tracklist:[
        StressOut.id,
    ]
}

export const ALBUMS = [
    Stress,
    EP,
    SalamayInterlude

]
