import React from 'react';
const Stack = (props)=>{
        
       let tf=props.startLayer??100;
        return <div 
        style={
            {...props.style,
                position:"absolute",
                height:props?.height?props.height:"100vh",
                width:props?.width?props.width:"100%",
               }
            }
            className={"jsk-stack jsk-stack-align-"+props.alignment}>
                {
            <div style={{width:"100%",height:"100%",position:"relative"}}>{
                React.Children.map(props.children, (elem, i)=>{
              
           return elem!==null?<div style={{
            ...elem.props.zStyle,
               pointerEvents:elem.props?.style?.pointerEvents??"inherit",
               bottom:elem.props?.style?.bottom??"inherit",
            position:"absolute",zIndex:(tf++),width:elem.props?.style?.sWidth??"100%",height:elem.props?.style?.sheight??"100%",
        }}key={elem.key?elem.key:'stack_'+i}>{elem}</div>:<></>
        })}</div>
       }
    </div>
    
}
Stack.defaultProps = {
    startLayer:"100"
 } 
export default Stack;