// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'
import 'swiper/components/pagination/pagination.min.css'
import '../App.css';
import { useState } from "react";
import { useEffect } from "react";
// import Swiper core and required modules
import SwiperCore, {
  EffectCoverflow, Pagination
} from 'swiper';
import Column from "./column";
import Row from "./row";
import { useMediaQuery } from "../Context/mediaQuery";
import { getAlbums as retrieveAlbums } from "../Database/dbmanager";
// install Swiper modules


export default function AlbumList(props) {
  const [albums, updateAlbums] = useState([]);
  const { device } = useMediaQuery();
  const getAlbums = async() => {
    let albums = await retrieveAlbums();
    updateAlbums(albums);
  }
  useEffect(() => {
    getAlbums();
  }, [])
  SwiperCore.use([EffectCoverflow, Pagination]);

  return <>
    <Swiper
    
      onSlideChange={
        (sw) => {
          if (props.updateCurrentAlbum)
            props.updateCurrentAlbum(albums[sw.activeIndex])
        }
      }
      style={{ width: "100%", "--swiper-navigation-color": "#fff",
      
      "--swiper-pagination-color": "#fff", 
      "--swiper-pagination-bullet-inactive-color": "#fff",
      "--swiper-pagination-bullet-inactive-opacity": "1",
      "--swiper-pagination-bullet-size": "16px",
      "--swiper-pagination-bullet-horizontal-gap": "6px"
    }}  effect="coverflow" grabCursor={true} centeredSlides={true} slidesPerView={device === "xs" ? 1 : 3} coverflowEffect={{
        "rotate": 30,
        "stretch": 0,
        "depth": 100,
        "modifier": 1,
        "arrow":false,
        "slideShadows": false
      }} pagination={true} className="mySwiper">
      {albums.map((album, index) => {
        return <SwiperSlide  key={"album_"+index} style={{ width: device === "xs" ? 250 : 250, }}>
          <Column >
            <Row alignment="centered">
              <div style={{ height: device === "xs" ? 250 : "250px", }}><img alt="" style={{ width: "100%", height: "100%", borderRadius: 8,border:"1px solid #ffffff57", objectFit: "cover", margin: "20px 0" }} src={album.cover} /></div>

            </Row>
            <div style={{ height: device === "xs" ? 30 : 30 }} />

            <label style={{ textAlign: "center", fontSize: 18, color: "white", fontWeight: "bold" }}>{album?.title ?? new Date()}</label>
            <p style={{ textAlign: "center", color: "white", }}>{new Intl.DateTimeFormat("en-GB", { year: "numeric" }).format(new Date(album?.releaseDate ?? new Date()))}</p>
          </Column>
        </SwiperSlide>
      })}
    </Swiper>
  </>
}