import Column from "./column";
import {  Dropdown, Header } from 'semantic-ui-react'
import LineIcon from "react-lineicons";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Utils from "../Logic/utils";
import $ from 'jquery';
export default function NavBar(props) {

    const [data, setData] = useState("new");
    
    const [options,setOptions] = useState([]);
    useEffect(()=>{
        let opt = Object.keys(props.pageData).map((d,i)=>{
            return {
                key:d,
                text:d.toLocaleUpperCase(),
                value:d,
                content:d.toLocaleUpperCase()
            }
        });
        setOptions(opt);

        // 
        $(window).on('load', function () {
            document.getElementById(data).scrollIntoView();
        });
        $(window).on('scroll',function(){
            
           Object.keys(props.pageData).forEach((e)=>{
               if(new Utils().isElementInView(document.getElementById(`${e}`),e)){
                   setData(e)
               }
           })
           
          });
          // eslint-disable-next-line 
    },[])
    return <Column style={{ width: 300, position: "fixed", top: 30, left: 20, zIndex: 200, }}>

        <Header as='h4' style={{ color: `${data === "new" ? "white" : "white"}` }}>
            <LineIcon name={props.pageData[data].icon} style={{ marginRight: 5 }} />
            <Header.Content>
                <Dropdown

                    inline
                    onChange={(e, d) => { setData(d.value);
                        
                        if(d.value==="new"){
                            $(window).scrollTop(0);
                        }else
                        document.getElementById(d.value).scrollIntoView();
                    }}
                    value={data}
                    options={options}
                />
            </Header.Content>
        </Header>

    </Column>
}
NavBar.propTypes = {
    pageData: PropTypes.object
}