import { Stack, Column, Row } from "../Components/layout.jsx"
import { useState } from "react";
import PropTypes from "prop-types"
import { useMediaPlayer } from "../Context/mediaController.js"
import { ALBUMS } from '../Mocks/mockEP';
import { useEffect } from "react";
import { List, Loader } from 'semantic-ui-react';

import AlbumList from "../Components/_albumList.jsx";
import { useMediaQuery } from "../Context/mediaQuery.js";
import ListenToMusic from "../Components/listen_music.jsx";
import { getAlbums,getSong } from "../Database/dbmanager.js";
import { Async } from "react-async";
import SongModule from "../Components/_song_mod.jsx";
const DiscographyPage = (props) => {
    const [songs, updateSingles] = useState([]);
    const [currentAlbum, setCurrentAlbum] = useState(ALBUMS[0]);
    const [, setAlbums] = useState([]);
    const { device, breakpoints } = useMediaQuery();
    const { currentTheme } = useMediaPlayer();
    const [currentSongSelected, setCurrentSongSelected] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const fetchAlbums = async() => {
        let albums = await getAlbums();
        setAlbums(albums);
        setCurrentAlbum(albums[0]);
        getSingles(albums[0]);
    }

    const getSingles = async(album) => {
        
        updateSingles(album.tracklist);
    }
    const buildSongs = () => {
        return <Column style={{ padding: device === "xs" ? "" : 20, marginBottom: "30px" }}>
            <Row>
                <h4 style={{ background: currentTheme?.main, color: !currentTheme?.isDark ? "white" : "black" }}>
                    {`${currentAlbum.title} »`}</h4>
                <Column style={{ fontSize: 14, color: "grey", margin: "0 10px" }}>
                    <label>
                        {` ${currentAlbum?.tracklist?.length ?? 0} Songs`}
                    </label>
                </Column>

                {/* <Column style={{fontSize:14,color:"grey",margin:"0 5px"}}>
                    <label>
                        {new Intl.DateTimeFormat("en-GB", { year: "numeric" }).format(new Date(currentAlbum?.releaseDate ?? new Date()))}
                    </label>
                </Column> */}
            </Row>



            <List selection inverted verticalAlign='middle' style={{ cursor: "pointer", }} >
                {
                    (songs ?? []).map((id, index) => {
                       
                        return <Async key={id+"__"+index} promiseFn={getSong} songId={id}>{({data,error,isPending})=>{
                           
                        return    error?<><p>{error}</p></>:isPending? <Loader/>:<SongModule openMusicModal = {(song)=>{
                          
                            setCurrentSongSelected(song);
                            setOpenModal(true)}} song={data}/>}}
                        </Async>
                    })
                }
            </List>
        </Column>
    }
    useEffect(() => {
        getSingles(currentAlbum);
        fetchAlbums();
        // eslint-disable-next-line 
    }, []);

    const buildDisso = () => {
        return <Column style={{ height: "100%", width: "100%", padding: "25px 0" }}>
            <div style={{ width: "100%", }}>
                <AlbumList updateCurrentAlbum={(al) => {

                    setCurrentAlbum(al);
                    getSingles(al);
                }} />
            </div>
        </Column>
    }
    return <><div {...props}>
        <Stack >
            <Column style={{ height: "100%", width: "100%" }}>
                <div style={{ height: '50%' }}>
                    <img alt="" src={currentAlbum?.cover} style={{
                        width: '100%',
                        height: '100%',
                        objectFit: "cover",
                        objectPosition: "top center"
                    }} />
                </div>
                <div style={{ height: '50%', background: "black" }}>
                </div>

            </Column>
            <div style={{ height: "100%", width: "100%", background: "linear-gradient(0deg, black 60%, #0000003d 120%)" }} />
            <Column alignment="start" style={{ height: "100%", width: "100%", padding: "0", overflowY: "auto", margin: "0 auto", maxWidth: breakpoints.lg, }}>

                <div style={{ width: "100%", padding: device === "xs" ? "" : device === "xl" ? "8vh" : "0" }}>
                    {buildDisso()}
                </div>
                <div style={{ width: "100%", padding: device === "xs" ? 12 : "40px"}}>
                    {buildSongs()}
                </div>
            </Column>
            <div style={{ height: "100%", pointerEvents: "none", sheight: "100px", bottom: 0, width: "100%", background: "linear-gradient(0deg, black 10%, #0000003d 40%)" }} />
        </Stack>
    </div>

        <ListenToMusic onHide={() => { setOpenModal(false) }} currentSong={currentSongSelected} openModal={openModal} />
    </>
}

export default DiscographyPage;

DiscographyPage.propTypes = {
    featured: PropTypes.object,

}