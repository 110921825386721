import { Row, Column, Stack } from '../Components/layout';
import AlbumModule from '../Components/_album_mod';
import { useMediaQuery } from '../Context/mediaQuery';
import {  Button, Divider, List } from 'semantic-ui-react';
import { Songs } from '../Mocks/mockSongs';
import romanize from '../Logic/roman_numerals';
import { useState } from 'react';


import LineIcon from "react-lineicons";
import { motion } from 'framer-motion';
import ListenToMusic from '../Components/listen_music';
import Announcer from '../Components/announcement';
import { useEffect } from 'react';
import {useAsync} from "react-async";
import { getAnnouncement } from '../Database/dbmanager';
import AnnounceMessage from '../Components/_announcementMessgae';
const HomeProjectView = (props) => {
    const { breakpoints, device } = useMediaQuery();
    const {data} = useAsync({promiseFn: getAnnouncement,});
    const [openModal,setOpenModal] = useState(false);
    const [openModal2,setOpenModal2] = useState(true);
    const [currentSong, setCurrentSong] = useState(Songs[0]);
    const openLinks = (song) => {
        setCurrentSong(song);
        setOpenModal(true);

    }
    useEffect(()=>{
        if(localStorage.getItem('announcement') === "true"){
            
            setOpenModal2(false);
        }else{
           
            setOpenModal2(true);
           
           
        }
    },[])
    const _buildPage = ()=>{
       
        const style = {
            //fontWeight: "bold",
            textAlign: "center",
            fontSize:device === "xs" || device === "md" ?"10vw":data.theme?.fontSize,
            opacity: 0.6,
            fontWeight: "bold",
            fontFamily:data.theme?.fontFamily,
            color: data.theme?.fontColor
        }
    
        return device === "xs" || device === "md" ? <div style={{
            width: "100%",
            height:"100vh"
        }}>
        <div style={{ width: "100%", height: "100vh", margin: "0 auto", padding: "2vh 20px", maxWidth: breakpoints.md, }} {...props}>
            <motion.div initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.5,type:"tween",ease:"easeIn"}} style={{ width: "100%", height: "100%" }}>
                <Column alignment={"centered"} style={{ width: "100%", height: "100%" }}>
                <h1 style={style}>{`${data.title}`}</h1>
                     <label style={{ textAlign: "center", color: "grey" }}>{`${data.subtitle}`}</label>
                   
                    
                    <Row alignment={"centered"}>
                        <Column>
                       
                            <div style={{ height: 15 }} />
                            <AlbumModule cover={data.cover} backcover={data.backcover} width={200} height={200} style={{ filter: `blur(${Date.now() < data.showCoverArt ? 30 : 0})` }} />

                        </Column>

                    </Row>

                    <div style={{ height: 15 }} />

                    <Row alignment={"centered"}>
                                    <div style={{ width: 100, background:data.theme?.colors?.action??"black",color:data.theme?.colors?.buttonText??"white" }} onClick={()=>{window.open(data.listen?.spotify,"_blank")}}  className="ui animated button" tabIndex="0">
                                        <Row className="visible content"><LineIcon name="spotify" /><div style={{ width: "10px" }} />Listen</Row>
                                        <div className="hidden content">
                                            <i className="right arrow icon"></i>
                                        </div>
                                    </div>
                                </Row>
               

                                <div style={{ height: 15 }} />
                   <label style={{ textAlign: "center", color: data?.theme?.fontColor??"black", fontWeight: "bold" }}>{data.releaseDate <= Date.now().valueOf() ? 'OUT NOW':`${release[0]}.${release[1]}.${release[2]}`}</label> <div style={{ height: 15 }} />
                   <AnnounceMessage/>
                    { data.type === "SONG" ? <></> :<>
                    <Divider horizontal style={{ padding: "0 10px" }} >Tracklist</Divider>
                    <List animated verticalAlign='middle' style={{ cursor: "pointer", }} >
                        {
                            data.tracklist?.map((id, index) => {
                                return <List.Item key={"home_"+index} onClick={() => { openLinks(id); }} style={{ textAlign: "center" }}>
                                    {romanize(index + 1) + ". " + Songs[id]?.title ?? ""}
                                </List.Item>
                            })
                        }
                    </List></>}

                </Column>
            </motion.div>
        </div>
        
    </div> : <div style={{ width: "100%", height: "100vh",}}>
        <div style={{ width: "100%", height: "86vh", margin: "0 auto", padding: "0 50px", maxWidth: breakpoints.md, }} {...props}>
            <Row alignment={"centered"} style={{ width: "100%", height: "100%" }}>
                <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={{  delay: 0.5,type:"tween",duration:1 }} style={{ width: "50%", height: "100%" }}>
                    <Column alignment="centered" style={{ width: "100%", height: "90vh" }}>
                        <Row alignment={"centered"}>
                            <Column>
                            {data.type === "SONG" ? <motion.div initial={{ opacity: 0, y: -50 }}  animate={{ opacity: 1, y: -10 }} transition={{ delay: 0.9,type:"tween",duration:1}}>
                                <h1 style={style}>{data.title}</h1>
                            </motion.div> :<></>}
                            <div style={{height:20}}/>
                            <Row alignment={"centered"}>
                                 <AlbumModule borderRadius={10} cover={data.cover} backcover={data.backcover} width={"50vh"} height={"50vh"} style={{borderRadius:"10px", filter: `blur(${Date.now() < data.showCoverArt ? 30 : 0})` }} />
                            </Row>
                               
                                <div style={{ height: 15 }} />  <label style={{ textAlign: "center", color: data?.theme?.fontColor??"white", fontWeight: "bold" }}>{data.releaseDate <= Date.now().valueOf() ? 'OUT NOW':`${release[0]}.${release[1]}.${release[2]}`}</label> <div style={{ height: 15 }} />
                                <Row alignment={"centered"}>
                                    <div style={{ width: 100, background:data.theme?.colors?.action??"black",color:data?.theme?.colors?.buttonText??"white" }} onClick={()=>{window.open(data.listen?.spotify,"_blank")}}  className="ui animated button" tabIndex="0">
                                        <Row className="visible content"><LineIcon name="spotify" /><div style={{ width: "10px" }} />Listen</Row>
                                        <div className="hidden content">
                                            <i className="right arrow icon"></i>
                                        </div>
                                    </div>
                                </Row>


                            </Column>

                        </Row>
                    </Column>
                    
                </motion.div>

                {data.type === "SONG" ? <></> :<div style={{ width: "10%" }} />}
                {
                    data.type === "SONG" ? <></> :
                    <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.6 }} style={{ width: "30%", height: "100%" }}><Column alignment="centered" style={{ width: "100%", height: "100%", marginBottom: "80px" }}>

                        <h1 style={{
                            //fontWeight: "bold",
                            textAlign: "start",
                            fontSize: data.theme?.fontSize,
                            opacity: 0.6,
                            color: data.theme?.fontColor
                        }}>{`${data.title}`}</h1>
                        <label style={{ textAlign: "start",  color: data.theme?.fontColor }}>{`${data.subtitle}`}</label>
                        <Divider />
                        <List animated  verticalAlign='middle' style={{ cursor: "pointer",overflowY:"auto", }} >
                            {
                                data.tracklist?.map((id, index) => {
                                    return <List.Item key={"tcklist_"+index} onClick={() => { openLinks(id);  }}>
                                        {romanize(index + 1) + ". " + Songs[id]?.title ?? ""}
                                    </List.Item>
                                })
                            }
                        </List>

                    </Column>
                    </motion.div>
                }
            </Row>
            
        </div>
        {<AnnounceMessage/>}
        <ListenToMusic onHide={()=>{setOpenModal(false)}} currentSong={currentSong} openModal={openModal}/>
    </div>

    }
   
    const release = data ? new Intl.DateTimeFormat("en-GB", { day: "numeric", month: "numeric", year: "2-digit" }).format(data.releaseDate).split("/"):new Date();
    return data ?
        <><Stack>
         <div style={{height:"100%",width:"100%",backgroundColor:data?.theme?.backgroundColor??"white"}}/>
            {  data.theme?.backgroundMedia ? 
             data.theme?.backgroundMedia.includes(".mp4") ? 
             <video playsinline src={data?.theme?.backgroundMedia??""}></video> :
             <Stack>
                <img alt="background" style={{height:"100%",width:"100%",objectFit:"cover"}} src={data?.theme?.backgroundMedia??""}/>
                <div style={{height:"100%",width:"100%",background:`linear-gradient(0deg,${data?.theme?.backgroundColor??"white"} 49%,transparent 100%)`,}}/>
             </Stack>
             :<></>
             }
            {_buildPage()}
        
        </Stack>
       
   {<Announcer onHide={() => { setOpenModal2(false); localStorage.setItem('announcement',"true"); }} openModal={openModal2} 
        footer= {<Button  onClick={()=>{
            document.getElementById("contact").scrollIntoView();
            setTimeout(()=>{
                setOpenModal2(false)
            }, 1000 );
        }} style={{ backgroundColor: "#4a3830", color: "white" }} fluid size='large'>
        Subscribe now!
    </Button>}   />}
    </>:<></>
}
export default (HomeProjectView);