
import PropTypes from "prop-types";
import { CloseButton, Modal } from "react-bootstrap";
import { Image, List } from "semantic-ui-react";

import Column from "./column";
import { useAsync } from "react-async";
import { getAnnouncementAlert } from "../Database/dbmanager";
const Announcer = (props)=>{
    const {data} = useAsync({promiseFn: getAnnouncementAlert,});
    return   <Modal
    size="xs"
    style={{backdropFilter:"blur(10px)",WebkitBackdropFilter:"blur(10px"}}
    show={props.openModal || (data?.expirydata> Date.now() && data?.showModal)}
    onBackdropClick={props.onHide}
    onHide={props.onHide}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    
       <Modal.Header><CloseButton onClick={props.onHide}/></Modal.Header>
      <Modal.Title  id="contained-modal-title-vcenter" style={{textAlign: "center"}}>
      <List verticalAlign='middle' style={{ cursor: "pointer", }} >
      <List.Item onClick={props.onHide}>
                          <Image  src={data?.cover ?? ""} 
                        //   style={{width: "100vw",height:200,objectFit:"contain"}}
                           />
                          <List.Content>
                              {/* +(Songs[id].feature.length>0 ?'(feat.  '+Songs[id].feature.join(", ")+')':"" */}
                              <List.Header style={{fontSize:"16px",fontWeight:"bold"}} >{data?.title??""}</List.Header>
                              <List.Description style={{fontSize:"12px"}}>{data?.subtitle ?? "" }</List.Description>
                          </List.Content>

                      </List.Item></List>
      </Modal.Title>
   
    <Modal.Body style={{textAlign: "center"}}>
        {
            <Column>
            
            {(data?.message??"").split(",").map((message, i)=><>
            <p style={{marginBottom: 5}}key= {"mess_"+i}>
        {message}
     </p></>
            )}
            </Column>
        }
     
    
    </Modal.Body>
    <Modal.Footer>
         {props?.footer??<></>}
    </Modal.Footer>
  </Modal>
}
export default Announcer;
Announcer.propTypes={
    onHide: PropTypes.func,
    footer:PropTypes.element,
    openModal:PropTypes.bool

}
