import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import * as d3 from "d3";
import { retrievedDrawnLines, saveDrawnLinesToDB } from "../Database/dbmanager";


const Line = ({ thickness, color ,points }) => {
    const line = useMemo(() => {
      return d3
        .line()
        .x((d) => d.x)
        .y((d) => d.y)
        .curve(d3.curveBasisOpen)
    }, [])
  
    return (
      <path
        d={line(points)}
        style={{
          stroke: color ?? "#898989",
          strokeWidth: thickness,
          strokeLinejoin: "round",
          strokeLinecap: "round",
          fill: "none",
        }}
      />
    )
  }

export const MouseDraw = React.forwardRef(({x,y,width,height,defThickness,enableDrawingMode},ref ) => {

    useImperativeHandle(ref, () => ({
        getColor(){
            return color;
        },
        changeColor(c){
          setColor(c);
          setCurrentLine((line) => ({
            ...line,
            color: c
          }));
        },
        changeThickness(thick){
          setThickness(thick);
          setCurrentLine((line) => ({
            ...line,
            thickness: thick
          }));
        },
            
        clearDrawing(){
            setLines([]);
            saveDrawnLinesToDB([]).then(() => setCanDraw(true)).catch((err) => console.log(err));
        }
    }));
    const [drawing, setDrawing] = useState(false);
    const [canDraw, setCanDraw] = useState(true);
    const [color, setColor] = useState("#898989");
    const [thickness, setThickness] = useState(defThickness ?? 3);
    const [currentLine, setCurrentLine] = useState({ thickness: defThickness, color:"#898989", points: [] });
    const [lines, setLines] = useState([]);
  
    const drawingAreaRef = useRef();
    function enableDrawing() {
        if(canDraw && enableDrawingMode){
              setCurrentLine({ thickness:thickness, points: [], color:color });
              setDrawing(true); 
        }
     
      }
      function disableDrawing() {
       if(canDraw && enableDrawingMode){ setDrawing(false);
        setLines((lines) => [...lines, currentLine]);
        saveDrawnLinesToDB([...lines, currentLine]).then(() => setCanDraw(true)).catch((err) => console.log(err));
      }
       
      }
      const mouseMove = useCallback(
        function (event) {
          
          
          const x = d3.pointer(event,this)[0];
          const y = d3.pointer(event, this)[1];
          if (drawing) {
            setCurrentLine((line) => ({
              ...line,
              points: [...line.points, { x, y }]
            }));
          }
        },
        [drawing]
      );
      const touchMove = useCallback(
        function (event) {
          const coord = event.targetTouches?event.targetTouches[0]:event;
          const x = (coord?.clientX ?? 0);
          const y = (coord?.clientY ?? 0)+5;
          
          if (drawing) {
            //console.log("drawing",currentLine)
            setCurrentLine((line) => ({
              ...line,
              points: [...line.points, { x, y }] //  points: [...line.points.filter((point)=>point.x !== x && point.y !== y)]
            }));
          
          }
        },
        [drawing]
      );
      const listenForLinesDrawnOnDB = () => {
        const unsubscribe = retrievedDrawnLines((lines) => {
            setLines(lines)
        });
        return unsubscribe;
        };
      useEffect(() => {
        const area = d3.select(drawingAreaRef.current);
        area.on("mousemove", mouseMove).on("touchmove", (event)=>{
          touchMove(event);
           if(enableDrawingMode)
           event.preventDefault();
        });
        listenForLinesDrawnOnDB();
        return () => area.on("mousemove", null).on("touchmove", null);
      }, [mouseMove,touchMove,enableDrawingMode]);
    return (
        <g
          transform={`translate(${x}, ${y})`}
          ref={drawingAreaRef}
          onMouseDown={enableDrawing}
          onMouseUp={disableDrawing}
          onTouchStart={enableDrawing}
          onTouchEnd={disableDrawing}
          cursor= {canDraw && enableDrawingMode?"crosshair":"default"}
        >
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            style={{ fill: "transparent",}}
          />
          {lines.map((line, i) => (
            <Line thickness={line.thickness} color={line.color} points={line.points} key={i} />
          ))}
          <Line thickness={currentLine.thickness} color={currentLine.color} points={currentLine.points} />
        </g>
      )
})